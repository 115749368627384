import React from 'react';
import { Link } from 'react-router-dom';

// Layout
import LikeUnlikeButtonVisiteur from './LikeUnlikeButtonVisiteur';
import ShareLink from '../../global/ShareLink';
import Note from '../../global/MoyenneNote';

//Global
import GestionTagLists from '../../global/GestionTagListDisplay';

//Contexte
import { useConfig } from '../../contexte/ConfigContext';

//Assets
import flecheDroite from '../../../assets/fleche-petite-droite.png';
import LogoLeekWeSimple from '../../../assets/Logo-LeekWe-simple.webp';
import flecheDirectionGauche from '../../../assets/fleche-direction-gauche.png';
import flecheDirectionDroite from '../../../assets/fleche-direction-droite.png';

const PublicationListeVisiteur = ({ publications, provenance }) => {
    
    const generalConfigSysteme = useConfig();

    return (

        <div className={`${provenance === 'PAGE_ORGANISATION' ? 'list-service-pageOrganisation-container' : ''}`}>


            {/* Flèche gauche */}
            {provenance === 'PAGE_ORGANISATION' && (

                <button 
                    className="scroll-arrow left-arrow" 
                    onClick={() => document.querySelector('.container-publication-pageOrganisation').scrollBy({ left: -200, behavior: 'smooth' })}
                >
                    <img 
                        src={flecheDirectionGauche} 
                        alt='fleche gauche'
                        style={{ width: '20px', height: '20px', marginTop: '7px', marginBottom: '1px', marginRight: "2px"}}
                    />
                </button>

            )}


            <div className={`${provenance === 'PAGE_ORGANISATION' ? 'container-publication-pageOrganisation' : 'container'}`}>

                <ul className={`${provenance === 'PAGE_ORGANISATION' ? 'user-list-pageOrganisation' : 'user-list'}`}>

                    {publications.map((publication) => (
                        <li key={publication.uuid} className="user-item">
                            
                            <a 
                                href={`https://www.leekwe.com/${publication.titre ? publication.titre.toLowerCase().replace(/\s+/g, '-') : 'n-a'}/${publication.uuid_organisation}`}   
                                rel="noopener noreferrer" 
                                className='no-style-link'
                            >
                                <div className="user-info">
                                    <img
                                        alt={`Profil de ${publication.titre_prestation || 'Utilisateur'}`}
                                        className="image-profil"
                                        src={publication.lien_photo_profil ? generalConfigSysteme.imageLinkBaseURL + publication.lien_photo_profil : LogoLeekWeSimple}
                                    />
                                    <div className="user-details">
                                        <h2 className="sousTitre2">{publication.titre_prestation || 'N/A'}</h2>
                                        <h3 className="sousTitre2Gris">{publication.titre || 'N/A'}</h3>
                                    </div>
                                </div>
                            </a>
        
                            <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                <img
                                    alt={`Publication de ${publication.titre_prestation || 'Utilisateur'} avec ${publication.titre || 'Organisation'}`}
                                    className="image-publication"
                                    // loading="lazy" - Reduit le temps de chargement à l'affichage de la page accueil
                                    src={generalConfigSysteme.imageLinkBaseURL + publication.lien_image}
                                />
                            </Link>
        
                            <span style={{ display: 'block', height: '20px' }}></span>
        
                            <div className="action-container">
                                <div className="left-actions">
                                    <LikeUnlikeButtonVisiteur 
                                        publication={publication} 
                                    />
                                    <ShareLink 
                                        generatedShareLink={`https://www.leekwe.com/publication/${publication.uuid}`} 
                                    />

                                    {publication.moyenne_avis_client !== null && publication.moyenne_avis_client !== undefined && publication.moyenne_avis_client !== "" && (
                                        <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                            <Note moyenne={publication.moyenne_avis_client} />
                                        </Link>
                                    )} 

                                </div>
                                <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                    <p className='description-simple'>Consulter</p>
                                    <img 
                                        src={flecheDroite} 
                                        alt='Flèche vers publication'
                                        style={{ width: '24px', height: '24px', marginLeft: '5px', marginTop: '2px' }}
                                    />
                                </Link>
                            </div>
        
                            <span style={{ display: 'block', height: '10px' }}></span>
                            
                            <Link to={`/publication/${publication.uuid}`} className="publication-link">

                                {publication.id_type_publication === 1 ? (
                                
                                    <p className="description-simple">
                                        {publication.titre_prestation || 'N/A'} réalisé par {publication.titre || 'N/A'}.
                                    </p> 

                                ) : publication.id_type_publication === 2 ? (
                                    
                                    publication.utilisateurs_lies?.length > 0 && (

                                        <p className="description-simple">
                                            {publication.utilisateurs_lies.map((utilisateur) => utilisateur.prenom || 'N/A').join(', ')} a bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                                        </p> 
                                        
                                    )

                                ) : (
                                    
                                    publication.utilisateurs_lies.length > 1 ? (
                                        
                                        <p className="description-simple">
                                            {publication.utilisateurs_lies.map((utilisateur) => utilisateur.prenom || 'N/A').join(', ')} {' '}
                                            ont bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                                        </p> 
                                        

                                    ) : (
                                        
                                        <p className="description-simple">
                                            {publication.utilisateurs_lies.map((utilisateur) => utilisateur.prenom || 'N/A').join(', ')} {' '}
                                            à bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                                        </p> 
                                    )
                                 

                                )}


                            </Link>
        
                            <span style={{ display: 'block', height: '10px' }}></span>
        
                            <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                <GestionTagLists 
                                    tags={publication.tag_prestation || []} 
                                />
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Flèche droite */}
            {provenance === 'PAGE_ORGANISATION' && (

                <button 
                    className="scroll-arrow right-arrow" 
                    onClick={() => document.querySelector('.container-publication-pageOrganisation').scrollBy({ left: 200, behavior: 'smooth' })}
                >
                    <img 
                        src={flecheDirectionDroite} 
                        alt='feche droite'
                        style={{ width: '20px', height: '20px', marginTop: '7px', marginBottom: '1px', marginLeft: "2px"}}
                    />
                </button>

            )}

        </div>

    );
    

    
};


export default PublicationListeVisiteur;
