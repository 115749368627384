import { useState } from 'react';
import axios from 'axios';


//Contexte
import { useConfig } from '../../contexte/ConfigContext';


/*
Récupération de la liste de publication générale lorsque l'utilisateur est connecté (Système de like/unlike)
*/

const useGetListePublicationSuggestion = (numberSkip, unixTimestampDate) => {

  const generalConfigSysteme = useConfig();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [publicationSuggestion, setPublicationSuggestion] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  const getListePublicationSuggestion = async () => {
    setLoading(true);
    setError(null);

    const data = JSON.stringify({
      numberSkip: String(numberSkip),
      unixTimestampDate: unixTimestampDate
    });
    

    const config = {
      method: 'post',
      url: `${generalConfigSysteme.apiUrl}/api/public/listePublicationsSuggestion`,
      headers: {
        'Content-Type': 'application/json' 
      },
      data: data
    };


    try {
      const response = await axios.request(config);
      setPublicationSuggestion(response.data);
      setResponseMessage('Publications récupérés avec succès.');
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { getListePublicationSuggestion, publicationSuggestion, loading, error, responseMessage };
};

export default useGetListePublicationSuggestion;
