import React, { createContext, useContext, useEffect, useState } from 'react';

const ConfigContext = createContext(null);

export const useConfig = () => {
  return useContext(ConfigContext);
};

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  




  useEffect(() => {

    const currentUrl = window.location.href;

    let configFile = '/config-production.json';  // Config production - Par défaut
    if (currentUrl.includes('preprod')) {
      configFile = '/config-pre-production.json';  // Config pré-production
    } else if (currentUrl.includes('localhost')) {
      configFile = '/config-pre-production.json';  // Config pré-production
    }

    fetch(configFile)
      .then(response => response.json())
      .then(data => {
        setConfig(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error loading config:', err);
        setError(err);
        setLoading(false);
      });

  }, []);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error loading config: {error.message}</div>;
  }

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};
