import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Stores
import useGetListePreferencesSysteme from '../../../components/store/utilisateur/post-listePreferencesSysteme';
import useGetUpdatePreferencesSysteme from '../../../components/store/utilisateur/post-updatePreferencesSysteme';

//Assets
import fermer from '../../../assets/fermer.png';

const ModalPreferencesUtilisateur = ({ changeUserPreference, isOpen, setIsOpen }) => {
    const [preferencesModifiees, setPreferencesModifiees] = useState([]);
    const token = localStorage.getItem('token'); 
    const navigate = useNavigate();

    const { 
        getListePreferencesSysteme, 
        preferencesSysteme, 
        // loading: loadingCategorieSystem, 
        // error: errorCategorieSystem 
    } = useGetListePreferencesSysteme();

    const { 
        getUpdatePreferencesSysteme, 
        updatePreferencesSysteme, 
        // loading: loadingUpdatePreferencesSysteme, 
        // error: errorUpdatePreferencesSysteme
    } = useGetUpdatePreferencesSysteme(preferencesModifiees);




    useEffect(() => {
        
        if (isOpen) {
            if(token)
            {   
                getListePreferencesSysteme();
            }
            document.body.style.overflow = 'hidden';
            setPreferencesModifiees([]);
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    
    const handleCategoryChange = (famillePreference, categorie) => {
        
        const categories = JSON.parse(famillePreference.categories);
        const updatedCategories = categories.map(cat => 
            cat.id === categorie.id 
                ? {...cat, is_selected: !cat.is_selected}
                : cat
        );
        famillePreference.categories = JSON.stringify(updatedCategories);
    
        setPreferencesModifiees(prev => {
            const existingPref = prev.find(p => p.id === categorie.id);
            if (existingPref && existingPref.is_selected === categorie.is_selected) {
                return prev.filter(p => p.id !== categorie.id);
            }
            return [...prev, {
                id: categorie.id,
                is_selected: !categorie.is_selected
            }];
        });
    };

    const handleSavePreferences = () => {
        getUpdatePreferencesSysteme();
        handleClose();
    };


    const handleClose = () => {
        const overlay = document.querySelector('.modal-preferences-overlay');
        overlay.classList.add('closing');
        setTimeout(() => {
            setIsOpen(false);
        }, 300);
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('modal-preferences-overlay')) {
            handleClose();
        }
    };



    useEffect(() => {

        if (updatePreferencesSysteme?.code === "PREFERENCE_UPDATE") {
            changeUserPreference();
            setTimeout(() => {
                getListePreferencesSysteme();
            }, 150);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePreferencesSysteme]);


    return (
        <>
            

            {isOpen && (
                <div className="modal-preferences-overlay" onClick={handleOverlayClick}>
                    <div className="modal-preferences-content">
                        <div className="modal-preferences-header">
                            <h2 className='Titre-menu-simple'>Vos préférences</h2>
                            <button onClick={handleClose} className="modal-preferences-close">
                                <img 
                                    src={fermer} 
                                    alt='Fermer'
                                    className='fermer-icone'
                                />
                            </button>
                        </div>

                        {token ? (

                            <>
                        
                                <div className="modal-preferences-body">
                                    <div className="modal-preferences-scroll">

                                        <span style={{ display: 'block', height: '15px' }}></span>

                                        {preferencesSysteme?.map((famillePreference) => (

                                            <div key={famillePreference.id_famille} className="famille-preference">
                                                
                                                <div className="Titre-menu-simple-organisation">{famillePreference.intitule_famille}</div>
                                                
                                                <span style={{ display: 'block', height: '15px' }}></span>

                                                <div className="categories-container">
                                                    {JSON.parse(famillePreference.categories).map((categorie) => (
                                                        <div 
                                                            key={categorie.id} 
                                                            className={`categorie-item ${categorie.is_selected ? 'categorie-selected' : ''}`}
                                                            onClick={() => handleCategoryChange(famillePreference, categorie)}
                                                        >
                                                            {/* <span className={`checkbox-circle ${categorie.is_selected ? 'checkbox-selected' : ''}`}>
                                                                {categorie.is_selected ? '✓' : ''}
                                                            </span> */}
                                                            <span className={`${categorie.is_selected ? 'sousTitre2-simple-white' : 'sousTitre2-simple-gray '}`}>{categorie.intitule}</span>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>


                                        ))}
                                        
                                    </div>
                                </div>

                                <div className="modal-preferences-footer">
                                    <button  className="modal-preferences-save-button" onClick={handleSavePreferences}> 
                                        <h2 className="sousTitre2-simple-white">Enregistrer mes préférences</h2>
                                    </button>
                                </div>

                            </>

                        ):(

                            <>  

                            
                                <div className="modal-preferences-connexion">

                                    <div className="Titre-menu-simple-organisation"> Connectez-vous </div>

                                    <span style={{ display: 'block', height: '10px' }}></span>

                                    <p className="sousTitre2Gris-center">
                                        Pour consulter et afficher les publications selon vos préférences, connectez-vous dès maintenant !
                                    </p>

                                    <span style={{ display: 'block', height: '25px' }}></span>

                                    <button  className="modal-preferences-save-button" onClick={() => navigate('/login')}> 
                                        <h2 className="sousTitre2-simple-white">Je me connecte</h2>
                                    </button>
                                    
                                </div>


                            </>

                        )}

                    </div>
                </div>
            )}
        </>
    );
};

export default ModalPreferencesUtilisateur;