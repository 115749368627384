import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NotificationBubble = ({ updateResult, errorResult, successMessage, navigation, navigationError = ""}) => {
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('green');
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    // console.log("error: ", errorResult)
    // console.log("updateResult: ", updateResult)
    // console.log("updateResult: ",updateResult?.message )
    
    if (updateResult || errorResult) {
      let displayMessage = '';
      let displayColor = 'green';

      
      if (errorResult?.response?.data) {
        if (errorResult.response.status === 400) {
          displayMessage = errorResult.response.data.message;
          displayColor = 'red';    
          
          if(navigationError !== "")
          {
            navigate(navigationError)
          }
    
        }
        else if (errorResult.response.status === 500) {
          displayMessage = errorResult.response.data.error;
          displayColor = 'red';

          if(navigationError !== "")
          {
            navigate(navigationError)
          }
        }
        else if (errorResult.response.status  === 429) {
          displayMessage = errorResult.response.data.message;
          displayColor = 'red';

          if(navigationError !== "")
          {
            navigate(navigationError)
          }

        }
        else if (errorResult.response.status  === 403) {

          if(errorResult.response.data.code === "INVALID_TOKEN")
          {
            localStorage.removeItem('token');
            window.location.reload();
          }
         
        }
        
      } 
      else if (errorResult?.code === "ERR_NETWORK") {
        displayMessage = "Le serveur à rencontré une erreur.";
        displayColor = 'red';
      }
      else if (errorResult !== "" && errorResult !== null && errorResult !== undefined ) {
        displayMessage = errorResult;
        displayColor = 'red';

        if(navigationError !== "")
        {
          navigate(navigationError)
        }

      }
      else if (updateResult?.affectedRows) {

        if(navigation !== "")
        {
          navigate(navigation)
        }

        displayMessage = successMessage;
        displayColor = 'green';
      }
      else if(updateResult?.status === 200)
      {
        if(navigation !== "")
        {
          navigate(navigation)
        }
  
        displayMessage = successMessage;
        displayColor = 'green';
      }
      else if (updateResult?.message) 
      {
        if(navigation !== "")
        {
          navigate(navigation)
        }
    
        displayMessage = successMessage;
        displayColor = 'green';
      }
       

      if (displayMessage) {

        setMessage(displayMessage);
        setColor(displayColor);

        setVisible(false);
        setTimeout(() => setVisible(true), 0);

        const hideTimer = setTimeout(() => {
          setVisible(false);
        }, 3000);

        return () => clearTimeout(hideTimer);

      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResult, errorResult, successMessage]);

  if (!visible || !message) return null;

 

  return (
    <div className="bubble" style={{ backgroundColor: color }}>
      {message}
    </div>
  );
};

export default NotificationBubble;
