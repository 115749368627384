import { useState } from 'react';
import axios from 'axios';


//Contexte
import { useConfig } from '../../contexte/ConfigContext';


/*
Récupération de la liste de publication générale lorsque l'utilisateur est connecté (Système de like/unlike)
*/

const useGetListePreferencesSysteme = () => {

  const generalConfigSysteme = useConfig();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [preferencesSysteme, setPreferencesSysteme] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  const getListePreferencesSysteme = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token'); 

    

    const config = {
      method: 'post',
      url: `${generalConfigSysteme.apiUrl}/api/utilisateurs/listePreferencesSysteme`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
      },
    };


    try {
      const response = await axios.request(config);
      setPreferencesSysteme(response.data);
      setResponseMessage('Publications récupérés avec succès.');
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { getListePreferencesSysteme, preferencesSysteme, loading, error, responseMessage };
};

export default useGetListePreferencesSysteme;
