import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Layout
import LikeUnlikeButtonUtilisateur from './LikeUnlikeButtonUtilisateur';
import ShareLink from '../../global/ShareLink';
import Note from '../../global/MoyenneNote';

//Global
import GestionTagLists from '../../global/GestionTagListDisplay';

//Contexte
import { useConfig } from '../../contexte/ConfigContext';

//Stores
import useGetCreateViewPublication from '../../../components/store/utilisateur/get-createViewPublication';


//Assets
import flecheDroite from '../../../assets/fleche-petite-droite.png';
import LogoLeekWeSimple from '../../../assets/Logo-LeekWe-simple.webp';
import flecheDirectionGauche from '../../../assets/fleche-direction-gauche.png';
import flecheDirectionDroite from '../../../assets/fleche-direction-droite.png';

const globalSeenUuids = new Set();

const PublicationListUtilisateur = ({ publications, provenance }) => {
    
    const generalConfigSysteme = useConfig();
    const [localPublications, setLocalPublications] = useState([]);
    const [uuidsToProcess, setUuidsToProcess] = useState([]);
    const [uuidPublication, setUuidPublication] = useState("");

    const { 
        getCreateViewPublication, 
        // viewPublication, 
        // loading: loadingViewPublication, 
        // error: errorViewPublication
    } = useGetCreateViewPublication(uuidPublication);
    

    const getClassName = (type) => {

        
        switch(provenance) {


          case 'PAGE_ORGANISATION':
            switch(type) {
                case 'service':
                    return 'list-service-pageOrganisation-container';
                case 'container':
                    return 'container-publication-pageOrganisation';
                case 'list':
                    return 'user-list-pageOrganisation';
                case 'userItem':
                    return 'user-item'
                default:
                    return '';
            }


          case 'PAGE_EXPERIENCE':
            switch(type) {
                case 'service':
                    return 'list-service-pageExperience-container';
                case 'container':
                    return 'container-publication-pageExperience';
                case 'list':
                    return 'user-list-pageExperience';
                case 'userItem':
                    return 'user-item-experience'
              default:
                return '';
            }


          default:
            switch(type) {
                case 'container':
                    return 'container';
                case 'list':
                    return 'user-list';
                case 'userItem':
                    return 'user-item'
                default:
                    return '';
            }

        }

      };



    useEffect(() => {
        setLocalPublications(publications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publications]);


    //Observer qui permet l'enregistrement des publication vu par l'utilisateur
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {

                        const uuid = entry.target.dataset.uuid;
                        const view_id = entry.target.dataset.view_id;

                        if(view_id === undefined)
                        {
                            if (!globalSeenUuids.has(uuid)) {

                                globalSeenUuids.add(uuid);
                                setUuidsToProcess(prev => [...prev, uuid]);

                            }
                        }
                        
                    }
                });
            },
            { 
                threshold: 0.1,
                rootMargin: '50px'
            }
        );

        setTimeout(() => {
            document.querySelectorAll('.user-item').forEach(item => observer.observe(item));
        }, 50);

        return () => observer.disconnect();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localPublications]);


    useEffect(() => {

        if (uuidsToProcess.length > 0 && uuidPublication === "") {
            const nextUuid = uuidsToProcess[0];
            setUuidPublication(nextUuid);
            setUuidsToProcess(prev => prev.slice(1));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuidsToProcess, uuidPublication]);

    useEffect(() => {
        
        if(uuidPublication !== "") {
            getCreateViewPublication();
            setUuidPublication("");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuidPublication]);



    return (

        
        <div  className={getClassName('service')}>


            {/* Flèche gauche */}
            {provenance === 'PAGE_ORGANISATION' && (

                <button 
                    className="scroll-arrow left-arrow" 
                    onClick={() => document.querySelector('.container-publication-pageOrganisation').scrollBy({ left: -200, behavior: 'smooth' })}
                >
                    <img 
                        src={flecheDirectionGauche} 
                        alt='fleche gauche'
                        style={{ width: '20px', height: '20px', marginTop: '7px', marginBottom: '1px', marginRight: "2px"}}
                    />
                </button>

            )}


            <div className={getClassName('container')}>
                
                <ul className={getClassName('list')}>

                    {localPublications.length > 0 ? (

                        <>  

                            {localPublications.map((publication) => (
                                    <li key={publication.uuid} className={getClassName('userItem')} data-uuid={publication.uuid} data-view_id={publication.view_id}>
                                    <a 
                                        href={`https://www.leekwe.com/${publication.titre ? publication.titre.toLowerCase().replace(/\s+/g, '-') : 'n-a'}/${publication.uuid_organisation}`}   
                                        rel="noopener noreferrer" 
                                        className='no-style-link'
                                    >

                                        <div className="user-info">
                                            <img
                                                alt={`${publication.titre_prestation || 'Utilisateur'} - Profil`}
                                                className="image-profil"
                                                src={publication.lien_photo_profil ? generalConfigSysteme.imageLinkBaseURL + publication.lien_photo_profil : LogoLeekWeSimple}
                                            />
                                            <div className="user-details">
                                                <h2 className="sousTitre2">{publication.titre_prestation || 'N/A'}</h2>
                                                <h3 className="sousTitre2Gris">{publication.titre || 'N/A'}</h3>
                                            </div>
                                        </div>

                                    </a>
                
                                    {publication.is_confidential !== 1 ? (
                                        <>
                                            <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                                <img
                                                    alt={`Publication de ${publication.titre_prestation || 'Utilisateur'} avec ${publication.titre || 'Organisation'}`}
                                                    className="image-publication"
                                                    loading="lazy"
                                                    src={publication.lien_image ? generalConfigSysteme.imageLinkBaseURL + publication.lien_image : LogoLeekWeSimple}
                                                />
                                            </Link>
                
                                            <span style={{ display: 'block', height: '20px' }}></span>
                
                                            <div className="action-container">
                                                <div className="left-actions">
                                                    <LikeUnlikeButtonUtilisateur
                                                        publication={publication}
                                                        setLocalPublications={setLocalPublications}
                                                    />
                                                    <ShareLink 
                                                        generatedShareLink={`https://www.leekwe.com/publication/${publication.uuid}`} 
                                                    />
                                                    <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                                        <Note moyenne={publication.moyenne_avis_client} />
                                                    </Link>
                                                </div>
                                                <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                                    <p className='description-simple'>Consulter</p>
                                                    <img 
                                                        src={flecheDroite} 
                                                        alt='Flèche vers publication'
                                                        style={{ width: '24px', height: '24px', marginLeft: '5px', marginTop: '2px' }}
                                                    />
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                            <p className="descriptionConfidentiel">
                                                La photo de cette publication n'est pas disponible. Elle a été définie comme confidentielle.
                                            </p>
                                        </Link>
                                    )}
                
                                    <span style={{ display: 'block', height: '10px' }}></span>
                
                                    <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                            
                                        {publication.id_type_publication === 1 ? (
                                    
                                            <p className="description-simple">
                                                {publication.titre_prestation || 'N/A'} réalisé par {publication.titre || 'N/A'}.
                                            </p> 

                                        ) : publication.id_type_publication === 2 ? (

                                            publication.utilisateurs_lies?.length > 0 && (
                                                <p className="description-simple">
                                                    {publication.utilisateurs_lies.map((utilisateur) => utilisateur.prenom || 'N/A').join(', ')} a bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                                                </p> 
                                            )

                                        ) : (
                                            
                                            publication.utilisateurs_lies.length > 1 ? (
                                                
                                                <p className="description-simple">
                                                    {publication.utilisateurs_lies.map((utilisateur) => utilisateur.prenom || 'N/A').join(', ')} {' '}
                                                    ont bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                                                </p> 
                                                

                                            ) : (
                                                
                                                <p className="description-simple">
                                                    {publication.utilisateurs_lies.map((utilisateur) => utilisateur.prenom || 'N/A').join(', ')} {' '}
                                                    à bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                                                </p> 
                                            )
                                        

                                        )}


                                    </Link>
                
                                    <span style={{ display: 'block', height: '10px' }}></span>
                
                                    <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                        <GestionTagLists tags={publication.tag_prestation || []} />
                                    </Link>
                                </li>
                            ))}

                        
                        </>
                    ) : (
                        <>
                            <p className="description">Aucune publications.</p>
                        </>
                    )}

                </ul>

            </div>

            {/* Flèche droite */}
            {provenance === 'PAGE_ORGANISATION' && (

                <button 
                    className="scroll-arrow right-arrow" 
                    onClick={() => document.querySelector('.container-publication-pageOrganisation').scrollBy({ left: 200, behavior: 'smooth' })}
                >
                    <img 
                        src={flecheDirectionDroite} 
                        alt='feche droite'
                        style={{ width: '20px', height: '20px', marginTop: '7px', marginBottom: '1px', marginLeft: "2px"}}
                    />
                </button>
                
            )}

        </div>


    );
    
};


export default PublicationListUtilisateur;
