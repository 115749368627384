import React from 'react';
// import PuffLoader from 'react-spinners/PuffLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';

//import loader from '../../assets/loader.gif';


const LoadingSpinner = () => {
    return (
        <div className="loaderContainer">
            {/* <PuffLoader color="#4A35AB" loading={true} speedMultiplier={0.8} size={100} aria-label="Loading Spinner"/> */}
            <PropagateLoader color="#8A2BE2" loading={true} speedMultiplier={0.8} size={25} aria-label="Loading Spinner"/>

            {/* <img src={loader} alt="Loading..." className="loading-gif" /> */}
        </div>
    );
};

export default LoadingSpinner;
