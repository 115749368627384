import { useState } from 'react';
import axios from 'axios';

//Contexte
import { useConfig } from '../../contexte/ConfigContext';

const useGetCreateViewPublication = (uuid_publication) => {

  const generalConfigSysteme = useConfig();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewPublication, setViewPublication] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  const getCreateViewPublication = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token'); 

    const data = JSON.stringify({
      uuid_publication: uuid_publication
    });

    const config = {
      method: 'post',
      url: `${generalConfigSysteme.apiUrl}/api/utilisateurs/createPublicationView`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      setViewPublication(response.data);
      setResponseMessage('Liste d\'organisation récupérés avec succès.');
    } catch (err) {
      setError('Une erreur est survenue lors de la récupération de la liste d\'organisation');
    } finally {
      setLoading(false);
    }
  };

  return { getCreateViewPublication, viewPublication, loading, error, responseMessage };
};

export default useGetCreateViewPublication;

